#root,

.merriweather {
  font-family: 'Merriweather Sans';
}

.hide {
  display: none;
}

cName {
  border-bottom: solid, black, 1px;
}

.wide {
  width: 100%;
}

.back-button {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
}

.back-arrow {
  width: 3rem;
  height: 3rem;
}

.center-text-vertical {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.uppercase {
  text-transform: uppercase;
}
.my-btn-large {
  width: 100%;
  height: 75%;
  margin: 2rem;
}

.tall {
  height: 30vmax;
}

.big-button-text {
  font-size: 3rem;
}

.purple-button {
  background-color: #6338a6;
  padding: 1rem;
}

.green-button {
  background-color: #38a69a;
  padding: 1rem;
  text-align: center;
  color: white;
  height: 100%;
  width: 100%;
}

.rounded {
  border-radius: 10px;
}

[badge]:after {
  background: #a63844;
  border-radius: 50%;
  color: #fff;
  content: attr(badge);
  font-size: 20px;
  margin-top: -10px;
  margin-bottom: -10px;
  min-width: 25px;
  min-height: 25px;
  padding: 1px;
  position: absolute;
  text-align: center;
}

[badge^='-']:after,
[badge='0']:after,
[badge='']:after {
  display: none;
}

.list-header {
  display: inline-block;
  margin: 2rem 0rem;
  font-size: 30px;
}

.list-main-item {
  font-size: 20px;
}

.btn-wide {
  width: 100%;
  margin: 1rem 0rem;
}

.btn-spacer {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.header-secondary-content {
  margin: 1.5rem 0rem;
  float: right;
  color: black;
}

.header-secondary-content i {
  font-size: 3rem;
  line-height: 1;
  margin: 0rem 2rem;
}

.detail-header {
  margin-top: 1rem;
}

.detail-header-text {
  font-size: 5vmax;
  font-weight: bold;
}

.detail-header-icon .material-icons {
  font-size: 5vh !important;
}

.detail-name-container input {
  display: inline-block;
  font-size: 4em;
}

.detail-icon-container {
  /* font-size: 50%; */

  border: solid black 1px;
  border-radius: 20%;
  width: 100%;
  height: 100%;
}

.detail-icon-container i {
  font-size: 100%;
}

.edit-list-header {
  margin-top: 5rem;
  font-size: 2em;
  border-bottom: solid 1px black;
  display: block;
  /* grid-template-columns: 1fr 1fr 1fr; */
}

.edit-list-header > div {
  text-align: center;
}

.edit-list-header i {
  font-size: 3rem;
  line-height: 1;
  /* margin: 0rem 2rem; */
  text-align: right;
}

.add-new-tail {
  text-align: center;
  font-style: italic;
  font-size: 1em;
}

.stricken {
  text-decoration: line-through;
}

.full-width-checkbox {
  width: 100%;
  color: black;
  text-align: left;
}

.checkbox-buffer {
  margin: 0.7rem 0;
}

/* Overwrite MaterializeCSS assignments */
nav .brand-logo {
  font-size: 2.5vmax !important;
}

.list-header {
  font-size: 3vmax;
}
